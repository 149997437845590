"use client";
import { CTAProps } from "../../types";
import React from "react";

export default function DynamicProductList({ title, children }: CTAProps) {
  return (
    <div className="py-10 lg:py-16 bg-putty-100 fullwidth">
      <h2 className="text-lg lg:text-3xl pb-6 mb-2">{title}</h2>
      <div>{children}</div>
    </div>
  );
}
