"use client";
import { DescriptionLink } from "@/components/CTA/variants/MultiCourse/descriptions/DescriptionLink";
import { ClientOnly } from "@/components/Cohesion/ClientOnly";

export default function LevelUpDescription() {
  let pos = 0;
  return (
    <ClientOnly>
      <div>
        <p>
          <DescriptionLink
            url="/executive-education"
            text="Executive education programs"
            position={`${++pos}`}
            variant="executive-education-2u"
          />
          &nbsp; prepare you to lead initiatives, teams, and organizations.
        </p>
        <ul className="list-disc">
          <li>
            Build advanced capabilities in career-relevant subjects like
            leadership, supply chain management, and sustainability.
          </li>
          <li>
            Learn, engage, and network with a small cohort of fellow
            professionals.
          </li>
          <li>
            Get personalized feedback and guidance from course tutors, student
            success advisors, and career coaches.
          </li>
          <li>
            Earn a valuable certificate of completion from leading universities.
          </li>
        </ul>
        <p>
          <i>Seeking a degree? Explore </i>
          <DescriptionLink
            url="/masters"
            text="master’s degrees"
            position={`${++pos}`}
            variant="masters"
          />
          <i> and </i>
          <DescriptionLink
            url="/doctorate"
            text="doctorate degrees"
            position={`${++pos}`}
            variant="doctorate"
          />
          <i>.</i>
        </p>
      </div>
    </ClientOnly>
  );
}
