"use client";
import { DescriptionLink } from "@/components/CTA/variants/MultiCourse/descriptions/DescriptionLink";
import { ClientOnly } from "@/components/Cohesion/ClientOnly";

export default function SharpenDescription() {
  let pos = 0;
  return (
    <ClientOnly>
      <div>
        <p>
          <DescriptionLink
            url="/certificates/professional-certificate"
            text="Professional certificates"
            position={`${++pos}`}
            variant="professional-certificate"
          />
          &nbsp;help you deepen your expertise and build practical skills.
        </p>
        <ul className="list-disc">
          <li>
            Choose from 400+ programs in subjects like computer science,
            software engineering, and leadership.
          </li>
          <li>
            Earn a valuable credential you can use to showcase your skills and
            share on professional profiles like LinkedIn.
          </li>
          <li>
            Stack certificates to earn university credit and, if you choose,
            build a pathway to a degree.
          </li>
          <li>
            Learn on your schedule with flexible, self-paced program curricula.
          </li>
        </ul>
        <p>
          <i>Honing your leadership skills? Explore </i>
          <DescriptionLink
            url="/executive-education"
            text="executive education programs"
            position={`${++pos}`}
            variant="executive-education-2u"
          />
          <b>
            <i>.</i>
          </b>
        </p>
      </div>
    </ClientOnly>
  );
}
