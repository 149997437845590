"use client";
import { DescriptionLink } from "@/components/CTA/variants/MultiCourse/descriptions/DescriptionLink";
import { ClientOnly } from "@/components/Cohesion/ClientOnly";

export default function EarnDescription() {
  let pos = 0;
  return (
    <ClientOnly>
      <div>
        <p>
          <DescriptionLink
            url="/masters"
            position={`${++pos}`}
            variant="masters"
            text="Master’s degrees"
          />
          <b>, </b>
          <DescriptionLink
            url="/doctorate"
            position={`${++pos}`}
            variant="doctorate"
            text="doctorate degrees"
          />
          <b>, and </b>
          <DescriptionLink
            url="/bachelors"
            position={`${++pos}`}
            variant="bachelors"
            text="undergraduate degrees"
          />
          &nbsp; help you unlock career opportunities.&nbsp;
          <b>Flexible schedules and financial aid available.</b>
        </p>
        <ul className="list-disc">
          <li>Learn from renowned faculty at the world’s best universities.</li>
          <li>
            Discover programs with flexible schedules, designed specifically for
            working professionals.
          </li>
          <li>Apply with the help of a dedicated admissions counselor.</li>
          <li>
            Get ongoing student and academic support, as well as 24/7 technical
            support.
          </li>
          <li>Connect with university alumni networks and career services.</li>
        </ul>
      </div>
    </ClientOnly>
  );
}
