"use client";
import React from "react";
import { InfoBoxProps } from "../types";
import Image from "next/image";
import { Button } from "@/components/ui/button";
import styles from "../InfoBox.module.css";
import { useRouter } from "next/router";

export default function StatisticalInfoBox({
  heading,
  icon,
  statisticLabel,
  statistic,
  ctaTitle,
  ctaLink,
  children,
}: InfoBoxProps) {
  const router = useRouter();
  const handleClick = () => {
    if (ctaLink) {
      router.push(`${window.location.pathname}/${ctaLink}`);
    }
  };

  return (
    <div className="container">
      <div className="w-full text-center lg:text-left">
        <h2 className="text-5xl">{heading}</h2>
      </div>
      <div className="w-full lg:flex lg:flex-row-reverse">
        <div
          className={
            styles.statisticsSection + ` flex flex-col items-center pb-6`
          }
        >
          <div className={styles.skewedBorder}></div>
          <Image src={icon} width={28} height={28} alt={heading} />
          <span className="text-sm font-roboto">{statisticLabel}</span>
          <span className="font-roboto text-lg font-bold">{statistic}</span>
        </div>
        <div className="border-t-[1px] pt-6 lg:border-none lg:w-2/3">
          <p className="text-base lg:mr-5">{children}</p>
        </div>
      </div>
      {ctaTitle && ctaLink && (
        <div className="w-full flex justify-center">
          <Button className="text-base" variant="outline" onClick={handleClick}>
            {ctaTitle}
          </Button>
        </div>
      )}
    </div>
  );
}
