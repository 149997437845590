import React from "react";
import { TagularIndexedProps } from "@/types/Tagular";

/**
 * Add an index property to all items we know should have them
 * @param children Input of Children
 * @warning This may not work well on lazy loaded components.
 */
export function indexChildren(children: React.ReactNode[]): React.ReactNode[] {
  const indexed = React.Children.map<React.ReactNode, React.ReactNode>(
    children,
    (child, index) => {
      // Check if the child is a valid React element
      if (React.isValidElement<TagularIndexedProps>(child)) {
        // Clone each child and add a new prop
        return React.cloneElement<TagularIndexedProps>(child, {
          index: index,
        }) as React.ReactNode;
      }
      return child;
    },
  );
  return indexed as React.ReactNode[];
}
