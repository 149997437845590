"use client";
import { useState } from "react";

import {
  Tabs as TabsUI,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "@/components/ui/tabs";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Card } from "@/components/ui/card";

export type TabsItem = {
  tabHeading: string;
  content: JSX.Element;
};

export type TabsProps = {
  tabs: TabsItem[];
};

export default function Tabs({ tabs }: TabsProps) {
  const [activeTab, setActiveTab] = useState<TabsItem>(tabs[0]);

  const handleTabChange = (
    tabSlug: string,
    triggeredByValidAction: boolean,
  ) => {
    if (triggeredByValidAction) {
      const tab = tabs.find((tab) => getTabSlug(tab.tabHeading) === tabSlug);
      if (tab) setActiveTab(tab);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent, tabSlug: string) => {
    if (event.key === "Enter") {
      handleTabChange(tabSlug, true); // Valid action
    }
  };

  const handleClick = (tabSlug: string) => {
    handleTabChange(tabSlug, true); // Valid action
  };

  const handleSelectChange = (tabHeading: string) => {
    const tab = tabs.find((tab) => tab.tabHeading === tabHeading);
    if (tab) setActiveTab(tab);
  };

  return (
    <div className="not-prose">
      {/* TABS */}
      <div className="hidden lg:block">
        <TabsUI
          value={getTabSlug(activeTab.tabHeading)}
          className="border rounded-xl w-full"
          onValueChange={(tabSlug) => handleTabChange(tabSlug, false)} // Default to invalid action
        >
          <TabsList>
            {tabs.map((tab) => (
              <TabsTrigger
                key={getTabSlug(tab.tabHeading)}
                value={getTabSlug(tab.tabHeading)}
                onClick={() => handleClick(getTabSlug(tab.tabHeading))}
                onKeyDown={(event) =>
                  handleKeyDown(event, getTabSlug(tab.tabHeading))
                }
              >
                {tab.tabHeading}
              </TabsTrigger>
            ))}
          </TabsList>
          {tabs.map((tab) => (
            <TabsContent
              key={getTabSlug(tab.tabHeading)}
              value={getTabSlug(tab.tabHeading)}
              className="m-0"
            >
              <Card className="rounded-t-none">{tab.content}</Card>
            </TabsContent>
          ))}
        </TabsUI>
      </div>
      {/* SELECT */}
      <div className="block lg:hidden">
        <Select value={activeTab.tabHeading} onValueChange={handleSelectChange}>
          <SelectTrigger
            className="bg-secondary text-secondary-foreground text-base px-4 py-0 h-12"
            id="selectbutton"
          >
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {tabs.map((tab) => (
              <SelectItem
                key={`select-item-${tab.tabHeading}`}
                value={tab.tabHeading}
                className={`text-base ${tab.tabHeading === activeTab.tabHeading ? "hidden" : ""}`}
              >
                {tab.tabHeading}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <Card className="rounded-t-none">{activeTab.content}</Card>
      </div>
    </div>
  );
}

function getTabSlug(tabHeading: string) {
  return tabHeading.toLowerCase().replace(/\s/g, "-");
}
