"use client";

import React, { useCallback, useEffect, useState } from "react";
import ProductCardList from "@/components/ProductCardList";
import GlobalAccordion from "@/components/CustomAccordion";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ProductType as CourseType } from "@/lib/course/types";
import getUserLocation from "@/lib/location/getUserLocationFromClient";
import { ElementClicked } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementClicked";
import { CommonVariants, ElementType } from "@/constants/tagular/main";
import makeEvent from "@/hooks/eventing/makeEvent";
import { EVENTS } from "@/hooks/eventing/types/events";
import { ElementViewed } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementViewed";
import { translateVariant } from "@/utils/tagular/dataTranslationMatrices";
import type {
  Category,
  ProductCardTabsAndPillsByUUIDProps,
  Subcategory,
} from "./types";
import useIsMobile from "@/hooks/useIsMobile";

function ProductCardTabsAndPillsByUUIDClient({
  title,
  categories,
  location,
  card_format_subtype,
}: ProductCardTabsAndPillsByUUIDProps) {
  const [filteredCategories, setFilteredCategories] = useState<Category[]>(
    categories || [],
  );
  const initialCategory =
    filteredCategories && filteredCategories.length > 0
      ? filteredCategories[0]
      : undefined;
  const [activeCategory, setActiveCategory] = useState<Category | undefined>(
    initialCategory,
  );
  const [viewText, setViewText] = useState<"View more" | "Show less">(
    "View more",
  );

  const isMobile = useIsMobile();

  useEffect(() => {
    const location = getUserLocation();
    if (location && location !== "US") {
      const newCategories =
        categories?.filter(
          (category) => category.productType !== CourseType.BOOTCAMP,
        ) || [];
      setFilteredCategories(newCategories);
    }
  }, [categories]);

  const initialSubcategory =
    activeCategory?.subcategories && activeCategory.subcategories.length > 0
      ? activeCategory?.subcategories[0]
      : undefined;
  const [activeSubcategory, setActiveSubcategory] = useState<
    Subcategory | undefined
  >(initialSubcategory);

  const getCategory = (categoryLabel: string) => {
    return filteredCategories?.find(
      (category) => category.categoryLabel === categoryLabel,
    );
  };

  const getSubcategory = (categoryLabel: string, subcategoryLabel: string) => {
    const category = getCategory(categoryLabel);
    return category?.subcategories?.find(
      (subcategory) => subcategory.subcategoryLabel === subcategoryLabel,
    );
  };

  const handleCategoryChange = (categoryLabel: string) => {
    setViewText("View more");
    const category = getCategory(categoryLabel);
    setActiveCategory(category);
    if (category?.subcategories && category.subcategories.length > 0) {
      setActiveSubcategory(category.subcategories[0]);
    }
  };

  const handleSubcategoryChange = (subcategoryLabel: string) => {
    setViewText("View more");
    const subcategory = getSubcategory(
      activeCategory?.categoryLabel || "",
      subcategoryLabel,
    );
    setActiveSubcategory(subcategory);
  };

  const hasMoreProducts = (products: any[]) => {
    const initialDisplayCount = 4;
    return products && products.length > initialDisplayCount;
  };

  const shouldShowAccordion = (subcategory: Subcategory | undefined) => {
    return !isMobile && hasMoreProducts(subcategory?.products || []);
  };

  const generateEventData = useCallback(
    (text: string) => {
      const webElement: ElementClicked["webElement"] = {
        elementType: ElementType.Link,
        location: location,
        position: "1",
        text: text,
        variant: activeCategory
          ? translateVariant(activeCategory.categoryLabel)
          : CommonVariants.AllProductsMixed,
        htmlId: "view-more-link",
      };
      return { webElement };
    },
    [activeCategory, location],
  );

  const sendViewedEvent = useCallback(
    (title: string) => {
      const event = makeEvent<ElementViewed, EVENTS>(generateEventData(title));
      event<ElementViewed>(EVENTS.ElementViewed)();
    },
    [generateEventData],
  );

  const sendClickedEvent = useCallback(
    (title: string) => {
      const event = makeEvent<ElementClicked, EVENTS>(generateEventData(title));
      event<ElementClicked>(EVENTS.ElementClicked)();
    },
    [generateEventData],
  );

  return (
    <div className="py-8 lg-py-20 mx-break-out flex justify-center bg-putty-100">
      <div className="container">
        <h2 className="text-xl lg:text-3xl font-bold mb-4">{title}</h2>
        <Tabs
          value={activeCategory?.categoryLabel}
          onValueChange={handleCategoryChange}
          className="border rounded-xl"
        >
          <div className="hidden lg:block rounded-t-xl">
            <TabsList>
              {filteredCategories?.map((category) => (
                <TabsTrigger
                  key={category.categoryLabel}
                  value={category.categoryLabel}
                  className="no-underline text-sm text-primary hover:text-primary data-[state=active]:font-bold bg-putty-100 rounded-none first:rounded-tl-xl last:rounded-tr-xl"
                >
                  {category.categoryLabel}
                </TabsTrigger>
              ))}
            </TabsList>
          </div>
          <div className="lg:hidden">
            <Select
              value={activeCategory?.categoryLabel}
              onValueChange={handleCategoryChange}
            >
              <SelectTrigger className="h-auto rounded-none bg-primary-400 text-base text-white hover:text-white no-underline p-4">
                <SelectValue placeholder={activeCategory?.categoryLabel} />
              </SelectTrigger>
              <SelectContent>
                {filteredCategories?.map((category) => (
                  <SelectItem
                    key={category.categoryLabel}
                    value={category.categoryLabel}
                  >
                    {category.categoryLabel}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          {filteredCategories?.map((category) => (
            <TabsContent
              key={`${category.categoryLabel}-content`}
              value={category.categoryLabel}
              className={`bg-white p-4 mt-0 rounded-b-xl ${activeCategory?.categoryLabel == category.categoryLabel ? "block" : "hidden"}`}
              forceMount={true}
            >
              <Tabs
                value={activeSubcategory?.subcategoryLabel}
                onValueChange={handleSubcategoryChange}
              >
                <TabsList className="overflow-x-scroll justify-start h-auto pt-4 gap-4">
                  {category?.subcategories?.map((subcategory) => (
                    <TabsTrigger
                      key={subcategory.subcategoryLabel}
                      value={subcategory.subcategoryLabel}
                      className="grow-0 rounded-xl border border-primary no-underline py-1 px-2 text-primary text-xs font-normal bg-putty hover:bg-primary hover:text-white data-[state=active]:bg-white data-[state=active]:text-primary data-[state=active]:hover:text-white"
                    >
                      {subcategory.subcategoryLabel}
                    </TabsTrigger>
                  ))}
                </TabsList>
                {category?.subcategories?.map((subcategory) => (
                  <TabsContent
                    key={`${category.categoryLabel}-${subcategory.subcategoryLabel}-content`}
                    value={subcategory.subcategoryLabel}
                    forceMount={true}
                    className={`${activeSubcategory?.subcategoryLabel == subcategory.subcategoryLabel ? "block" : "hidden"}`}
                  >
                    <ProductCardList
                      products={
                        isMobile
                          ? subcategory.products || []
                          : subcategory.products?.slice(0, 4) || []
                      }
                      location={location}
                      cardFormatSubType={card_format_subtype}
                      shouldScroll={isMobile ? true : viewText !== "Show less"}
                    />
                    {shouldShowAccordion(subcategory) && (
                      <div className="flex w-full justify-center">
                        <GlobalAccordion
                          title={`${viewText} ${subcategory.subcategoryLabel.toLowerCase()} ${category.categoryLabel.toLowerCase()}`}
                          openOnInit={false}
                          titleClassName="inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 text-primary underline-offset-4 h-10 px-4 text-base not-prose underline hover:no-underline hover:font-bold"
                          titleWrapperClasses="self-center"
                          onStateChange={(isOpen, title) => {
                            setViewText(!isOpen ? "Show less" : "View more");
                            sendClickedEvent(title);
                          }}
                          onViewed={(title) => sendViewedEvent(title)}
                        >
                          <ProductCardList
                            className="pt-0"
                            products={subcategory.products?.slice(4) || []}
                            location={location}
                            shouldScroll={false}
                          />
                        </GlobalAccordion>
                      </div>
                    )}
                  </TabsContent>
                ))}
              </Tabs>
            </TabsContent>
          ))}
        </Tabs>
      </div>
    </div>
  );
}

export default ProductCardTabsAndPillsByUUIDClient;
