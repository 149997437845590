"use client";
import React, { useState } from "react";
import { ExpandableBlockProps } from "../types";

export default function ExpandableBlock({
  heading,
  maxHeight = "200px",
  blur = false,
  children,
}: ExpandableBlockProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white">
      <p className="text-xl mb-3 text-primary font-bold">{heading}</p>
      <div
        className={`overflow-hidden transition-max-height duration-500 ease-in-out relative ${isExpanded ? "max-h-screen" : `max-h-\[${maxHeight}\]`}`}
      >
        {children}
        {blur && !isExpanded && (
          <div className="absolute bottom-0 left-0 w-full h-12 bg-gradient-to-t from-white via-white/80 to-transparent pointer-events-none"></div>
        )}
      </div>
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="text-black-100 underline"
      >
        {isExpanded ? "Show less" : "Show more"}
      </button>
    </div>
  );
}
