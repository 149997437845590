"use client";
import Image from "next/image";
import { usePathname } from "next/navigation";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import styles from "./index.module.css";

type Props = {
  heading: string;
  subheading: string;
  logo_src: string;
  logo_alt: string;
  logo_width: number;
  logo_height: number;
  lead_form_url: string;
  bg_color: string;
  bg_image?: string;
  children: React.ReactNode;
};
export default function HeroBootcamps({
  heading,
  subheading,
  lead_form_url,
  bg_color,
  bg_image,
  logo_src,
  logo_alt,
  logo_width,
  logo_height,
  children,
}: Props) {
  const pathname = usePathname();
  return (
    <div
      className="text-white my-0 mx-auto min-h-[500px] p0 pb-6 lg:p-16 flex flex-col overflow-hidden max-w-7xl items-start"
      style={{ backgroundColor: bg_color }}
    >
      <div className="flex gap-8 flex-col lg:flex-row items-center lg:items-start ">
        <div className="flex flex-col items-center lg-items-start">
          <div className="p-16 pb-6 lg:px-0 lg:pt-0">
            <Breadcrumbs variant="light" path={pathname} />
          </div>
          <div className="flex gap-8 flex-col items-center lg:items-start px-16 lg:px-0">
            <Image
              src={logo_src}
              alt={logo_alt}
              width={logo_width}
              height={logo_height}
              className="align-middle overflow-clip"
            />
            <h1 className="text-6xl text-center lg:text-left mb-2 mt-0">
              {heading}
            </h1>
            <p className="text-white m-0 text-lg leading-9">{subheading}</p>
            <div className={styles.list}>{children}</div>
          </div>
        </div>
        <div>
          <iframe src={lead_form_url} title="Lead Form"></iframe>
        </div>
      </div>
    </div>
  );
}
