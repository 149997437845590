import { ProductType as CourseType } from "@/lib/course/types";
import { ProgramType } from "@/lib/program/types";

export const NO_RESULTS = "Sorry, we don’t have results right now";

export const productTypes = [
  CourseType.COURSE,
  CourseType.EXECUTIVE_EDUCATION,
  CourseType.BOOTCAMP,
  ProgramType.MASTERS,
  ProgramType.BACHELORS,
];

export const productTypeIconList: {
  [key: string]: {
    data: { icon: string; title: string; description: string }[];
  };
} = {
  [CourseType.COURSE]: {
    data: [
      {
        icon: "check-circle",
        title: "Stand out in your field",
        description:
          "Use the knowledge and skills you have gained to drive impact at work and grow your career.",
      },
      {
        icon: "clock",
        title: "Learn at your own pace",
        description:
          "On your computer, tablet or phone, online courses make learning flexible to fit your busy life.",
      },
      {
        icon: "trending-up",
        title: "Earn a valuable credential",
        description: "Showcase your key skills and valuable knowledge.",
      },
    ],
  },
  [CourseType.EXECUTIVE_EDUCATION]: {
    data: [
      {
        icon: "check-circle",
        title: "Outcomes-based design",
        description:
          "An immersive cohort-based learning experience that is designed to optimize learning outcomes and ensure global networking opportunities.",
      },
      {
        icon: "trending-points",
        title: "Real-world impact",
        description:
          "Courses are tailored to address today’s organizational issues and opportunities so that you can make effective decisions to impact change.",
      },
      {
        icon: "customer-service",
        title: "Dedicated support",
        description:
          "Success Advisers provide administrative and technical support 24/7 and our tutors, who are subject matter experts, offer academic support.",
      },
    ],
  },
  [CourseType.BOOTCAMP]: {
    data: [
      {
        icon: "check-circle",
        title: "Results-driven curriculum",
        description:
          "Led by industry experts, our boot camp programs equip you with in-demand skills, a portfolio and a university-backed certification.",
      },
      {
        icon: "clock",
        title: "Flexible learning experience",
        description:
          "With full- and part-time options, night and weekend classes, and self-guided coursework, you’ll earn your certificate in 12, 18 or 24 weeks.",
      },
      {
        icon: "customer-service",
        title: "Dedicated career support",
        description:
          "Work with a career coach, leverage professional resources, attend events and access job referrals from our network of 250+ employers.",
      },
    ],
  },
  [ProgramType.MASTERS]: {
    data: [
      {
        icon: "check-circle",
        title: "Prestigious",
        description:
          "An immersive cohort-based learning experience that is designed to optimize learning outcomes and ensure global networking opportunities.",
      },
      {
        icon: "clock",
        title: "Flexible",
        description:
          "Choose your path with a range of completion options and class times.",
      },
      {
        icon: "trending-up",
        title: "Equivalent to on-campus",
        description:
          "Earn the same degree and complete the same curriculum as on-campus students.",
      },
    ],
  },
  [ProgramType.BACHELORS]: {
    data: [
      {
        icon: "check-circle",
        title: "Prestigious",
        description: "Discover degrees from leading universities.",
      },
      {
        icon: "clock",
        title: "Online flexibility",
        description:
          "Using innovative technology, our programs transform the undergraduate learning experience, offering flexibility and community online.",
      },
      {
        icon: "people",
        title: "Collaborative connections",
        description:
          "All online programs allow you to collaborate with peers and faculty while exploring your passions and developing in-demand skills.",
      },
    ],
  },
};
