"use client";
import { DescriptionLink } from "@/components/CTA/variants/MultiCourse/descriptions/DescriptionLink";
import { ClientOnly } from "@/components/Cohesion/ClientOnly";

export default function ChangeDescription() {
  let pos = 0;
  return (
    <ClientOnly>
      <div className="Content_intro__4CkTF">
        <p>
          <DescriptionLink
            url="/boot-camps"
            text="Boot camps"
            position={`${++pos}`}
            variant="bootcamp-2u"
          />
          &nbsp; prepare you to start or advance your career in technical
          fields.
        </p>
        <ul className="list-disc">
          <li>
            Build in-demand skills in AI, cybersecurity, coding, and more.
          </li>
          <li>
            Develop a professional portfolio to share with prospective
            employers.
          </li>
          <li>
            Choose from full- and part-time options, and night and weekend
            classes.
          </li>
          <li>Earn a university-backed certificate in 12, 18, or 24 weeks.</li>
          <li>
            Access career coaching and job referrals from a network of 250+
            employers.
          </li>
        </ul>
        <p>
          <i>Strengthening your </i>résumé<i>? Explore </i>
          <DescriptionLink
            url="/certificates/professional-certificate"
            text="professional certificates"
            position={`${++pos}`}
            variant="professional-certificate"
          />
          <b>.</b>
        </p>
      </div>
    </ClientOnly>
  );
}
