"use client";
import { Button } from "@/components/ui/button";
import React, { useEffect, useState } from "react";
import ButtonCarousel from "@/components/ui/buttonCarousel";
import {
  DEFAULT_USE_IN_VIEW_OPTIONS,
  ElementShared,
  ElementType,
} from "@/constants/tagular/main";
import { convertElementSharedToWebElement } from "@/utils/tagular/helpers";
import { ClientOnly } from "@/components/Cohesion/ClientOnly";
import useElementEvents from "@/hooks/eventing/useElementEvents";
import { useInView } from "react-intersection-observer";

export type DynamicProductListItem = {
  category: string;
  productList: React.ReactNode[];
};

const ViewMoreLink = ({
  tagularSettings,
  text,
  onClick,
}: {
  tagularSettings: ElementShared;
  text: string;
  onClick: () => void;
}) => {
  const url = "/";
  const webElement = convertElementSharedToWebElement(tagularSettings);

  webElement.text = text;

  const { elementViewed, elementClicked } = useElementEvents({ webElement });

  const { inView, ref } = useInView(DEFAULT_USE_IN_VIEW_OPTIONS);

  useEffect(() => {
    if (inView) {
      elementViewed();
    }
  }, [inView, elementViewed]);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    elementClicked();
    onClick();
  };

  return (
    <a
      href={url}
      ref={ref}
      className="text-[#33] text-base underline hover:font-bold hover:no-underline mx-auto"
      onClick={handleClick}
    >
      {text}
    </a>
  );
};

export type DynamicProductListProps = {
  products: DynamicProductListItem[];
};

export default function DynamicProductListItems({
  products,
}: DynamicProductListProps) {
  const [activeCategory, setActiveCategory] = useState<DynamicProductListItem>(
    products[0],
  );
  const [showAllContent, setShowAllContent] = useState(false);
  const tagularSettings: ElementShared = {
    webElement: {
      elementType: ElementType.Link,
      location: "trending-products",
      position: "1",
      htmlId: "view-more-popular-products-link",
      text: "", // Set at component level
    },
  };
  const visibleProducts = showAllContent
    ? activeCategory.productList
    : activeCategory.productList.slice(0, 4);
  return (
    <div className="py-4 lg:py-8 px-2">
      <div className="mb-4">
        <ButtonCarousel
          buttons={products.map((product) => (
            <Button
              key={product.category}
              onClick={() => setActiveCategory(product)}
              size="sm"
              radius="sm"
              variant={
                activeCategory.category === product.category
                  ? "primaryOutline"
                  : "lightOutline"
              }
            >
              {product.category}
            </Button>
          ))}
        />
      </div>
      <div className="hidden lg:flex flex-col gap-6 justify-center">
        <div className="flex flex-wrap justify-center px-6 gap-y-6 gap-x-5 w-auto m-auto">
          {visibleProducts}
        </div>
        {activeCategory.productList.length > 4 && (
          <ClientOnly>
            <ViewMoreLink
              tagularSettings={tagularSettings}
              onClick={() => setShowAllContent(!showAllContent)}
              text={
                (showAllContent ? "Show less" : "View more") +
                " popular executive"
              }
            />
          </ClientOnly>
        )}
      </div>
      <div className="flex lg:hidden gap-5 px-6 pb-6 overflow-auto">
        {activeCategory.productList}
      </div>
    </div>
  );
}
