"use client";
import styles from "./index.module.css";
import RegisterNow from "@/components/CTA/variants/MultiCourse/Content/RegisterNow";

type MultiCourseContentProps = {
  heading: string;
  introduction: JSX.Element;
  productList: React.ReactNode[];
};
export default function MultiCourseContent({
  heading,
  introduction,
  productList,
}: MultiCourseContentProps) {
  return (
    <div className="py-10 px-4 lg:p-5 xl:p-10">
      <div className="flex flex-col lg:flex-row gap-8 lg:gap-4 justify-between">
        <div className="lg:w-[45%] w-[100%]">
          <h2 className="pb-6 mt-0 mb-2 text-2xl text-primary">{heading}</h2>
          <div className={styles.intro}>{introduction}</div>
        </div>
        <div className="p-0 lg:pt-14 flex flex-wrap md:flex-nowrap gap-6 mx-auto justify-center md:items-start">
          {productList && productList.length > 2
            ? productList.slice(0, 2)
            : productList}
        </div>
      </div>
      <RegisterNow />
    </div>
  );
}
