"use client";
import * as React from "react";
import IframeResizer from "@iframe-resizer/react";
import { ContentNode, QuizComponentProps } from "../types";
import { INLINES } from "@contentful/rich-text-types";
import { formatRichText } from "@/utils/functions/formatting";

const formatContent = (text: string, iframeHeight: number) => {
  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node: ContentNode, children: string) => (
        <div className="d-block position-relative flex-shrink-0 flex-grow-1 order-xl-1">
          <IframeResizer
            license="xxx"
            title={children}
            style={{ width: "100%", height: "100vh" }}
            src={node.data.uri}
            className="d-block"
            checkOrigin={false}
            frameBorder={0}
            scrolling="omit"
          />
        </div>
      ),
    },
  };

  return formatRichText(text, options);
};

const DefaultQuizComponent = ({ text, iframeHeight }: QuizComponentProps) => {
  return <div className="p-0">{formatContent(text, iframeHeight)}</div>;
};

export default DefaultQuizComponent;
