"use client";
import React, { Children, isValidElement } from "react";
import type { DynamicProductListItem } from "@/components/CTA/variants/DynamicProductList/Items";
import DynamicProductListItems from "@/components/CTA/variants/DynamicProductList/Items";
import { indexChildren } from "@/utils/tagular/reactHelpers";

type ShortCodeDynamicProductListItemsProps = {
  children: React.ReactNode[];
};

export function ShortCodeDynamicProductListItems({
  children,
}: ShortCodeDynamicProductListItemsProps) {
  const productList: DynamicProductListItem[] = [];
  Children.forEach(children, (child) => {
    if (isValidElement(child)) {
      productList.push({
        category: child?.props?.category as string,
        productList: indexChildren(child?.props?.children),
      });
    }
  });
  return <DynamicProductListItems products={productList} />;
}

type ShortCodeDynamicProductListItemProps = {
  category: string;
  children: React.ReactNode[];
};

export function ShortCodeDynamicProductListItem({
  children,
}: ShortCodeDynamicProductListItemProps) {
  return <div>{children}</div>;
}
