"use client";

import { ElementViewed } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementViewed";
import { CommonVariants, ElementType } from "@/constants/tagular/main";
import { useInView } from "react-intersection-observer";
import useElementEvents from "@/hooks/eventing/useElementEvents";
import { useEffect } from "react";
import { hyphenateForTagular } from "@/utils/tagular/helpers";

type Props = {
  href: string;
  text: string;
  html_id?: string;
  location?: string;
  class_name?: string;
  name?: string;
  element_type?: string;
  position?: string;
};

export default function EventLink({
  href,
  text,
  html_id = "",
  location = "",
  class_name = "",
  name = "",
  element_type = "",
  position = "",
}: Props) {
  const webElement: ElementViewed["webElement"] = {
    elementType:
      element_type === "entry" ? ElementType.Entry : ElementType.Link, // Default is "LINK"
    htmlId: html_id,
    location: location,
    text: text,
    position: position ? position : "1",
    ...(name && {
      name: hyphenateForTagular(name),
      variant: CommonVariants.AllProductsMixed,
    }),
  };

  const { elementViewed, elementClicked } = useElementEvents({ webElement });
  const { inView, ref } = useInView();

  useEffect(() => {
    if (inView) {
      elementViewed();
    }
  }, [inView, elementViewed]);

  const handleClick = () => {
    elementClicked({ outboundUrl: href });
  };

  return (
    <a className={class_name} href={href} ref={ref} onClick={handleClick}>
      {text}
    </a>
  );
}
