import { useState, useEffect } from "react";

const DEFAULT_BREAKPOINT_WIDTH = 768;

/**
 * Determine if the current observer is a mobile device (or is in mobile format)
 * @param breakpoint
 */
const useIsMobile = (breakpoint = DEFAULT_BREAKPOINT_WIDTH) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < breakpoint);
    };

    checkIsMobile();

    window.addEventListener("resize", checkIsMobile);

    return () => window.removeEventListener("resize", checkIsMobile);
  }, [breakpoint]);

  return isMobile;
};

export default useIsMobile;
