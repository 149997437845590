import { ReactNode } from "react";
import { cn } from "@/lib/utils";

export interface IconTextListProps {
  children: ReactNode;
  className?: string;
  small?: boolean;
}

export default function IconTextList({
  children,
  className = "",
  small = false,
}: IconTextListProps) {
  const baseClassName = `fullwidth px-4 md:px-0 background-light ${small ? "py-2" : "py-8"}`;
  return (
    <div className={cn(baseClassName, className)}>
      <div className="w-full">
        <div className="md:flex flex-nowrap m-auto w-full">{children}</div>
      </div>
    </div>
  );
}
