import { cn } from "@/lib/utils";
import getIconFromName from "@/utils/functions/getIconfromName";
interface IconTextListItemProps {
  icon: string;
  header: string;
  boldHeader?: boolean;
  description: string;
  small?: boolean;
  className?: string;
  link?: string;
}

export default function IconTextListItem({
  icon,
  header,
  boldHeader = false,
  description,
  small = false,
  className,
  link,
}: IconTextListItemProps) {
  return (
    <div
      className={cn("md:text-left flex-grow basis-1/3 pb-4 md:pb-0", className)}
    >
      <div className="flex items-start space-x-4">
        <div className="w-[48px] h-[48px]">{getIconFromName(icon)}</div>
        <div className="md:pr-12">
          <div
            className={`text-primary mt-0 md:-md-1 ${small ? "text-base mb-0 leading-[1.5rem]" : "text-lg md:mb-2"} ${boldHeader ? "font-bold" : ""}`}
          >
            {header}
          </div>
          <span className={`mb-4 md:mb-0 text-sm`}>
            {link ? (
              <a className="underline cursor-pointer" href={link}>
                {description}
              </a>
            ) : (
              description
            )}
          </span>
        </div>
      </div>
    </div>
  );
}
