"use client";
import { useRef, useState, useEffect, useCallback, ReactNode } from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";

type ButtonCarouselProps = {
  buttons: ReactNode;
};

export default function ButtonCarousel({ buttons }: ButtonCarouselProps) {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [canScroll, setCanScroll] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const checkScroll = useCallback(() => {
    const container = scrollContainerRef.current;
    if (container) {
      const hasOverflow = container.scrollWidth > container.clientWidth;
      setCanScroll(hasOverflow);
      setCanScrollLeft(container.scrollLeft > 0);
      setCanScrollRight(
        container.scrollLeft < container.scrollWidth - container.clientWidth,
      );
    }
  }, []);

  useEffect(() => {
    checkScroll();
    window.addEventListener("resize", checkScroll);
    return () => window.removeEventListener("resize", checkScroll);
  }, [checkScroll]);

  const scroll = (direction: "left" | "right") => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = direction === "left" ? -200 : 200;
      container.scrollBy({ left: scrollAmount, behavior: "smooth" });
      setTimeout(checkScroll, 300);
    }
  };
  return (
    <div className="w-full mx-auto flex items-stretch space-x-2">
      {canScroll && (
        <div className="flex items-center pb-4">
          <Button
            variant="ghostPrimary"
            size="icon"
            onClick={() => scroll("left")}
            disabled={!canScrollLeft}
            className="flex-shrink-0"
          >
            <ArrowLeftIcon className="h-4 w-4" />
            <span className="sr-only">Scroll left</span>
          </Button>
        </div>
      )}
      <div className="relative flex-grow overflow-hidden">
        <div
          ref={scrollContainerRef}
          className="flex space-x-2 p-2 overflow-x-auto scrollbar-hide"
          onScroll={checkScroll}
        >
          {buttons}
        </div>
      </div>
      {canScroll && (
        <div className="flex items-center pb-4">
          <Button
            variant="ghostPrimary"
            size="icon"
            onClick={() => scroll("right")}
            disabled={!canScrollRight}
            className="flex-shrink-0"
          >
            <ArrowRightIcon className="h-4 w-4" />
            <span className="sr-only">Scroll right</span>
          </Button>
        </div>
      )}
    </div>
  );
}
