import React from "react";
import type { IconProps } from "./types";

const defaultProps = {
  svgProps: {
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    role: "img",
    focusable: false,
    "aria-hidden": true,
  },
  pathProps: {
    fill: "currentColor",
  },
};

const MoneyOutline = ({
  svgProps = defaultProps.svgProps,
  pathProps = defaultProps.pathProps,
}: IconProps) => {
  const mergedSvgProps = { ...defaultProps.svgProps, ...svgProps };
  const mergedPathProps = { ...defaultProps.pathProps, ...pathProps };

  return (
    <svg {...mergedSvgProps}>
      <path
        d="M19.25 37L22.75 34.9444L26.25 37V22.6111H19.25M26.25 12.3333V8.22222L22.75 10.2778L19.25 8.22222V12.3333L15.75 14.3889L19.25 16.4444V20.5556L22.75 18.5L26.25 20.5556V16.4444L29.75 14.3889M31.5 0H3.5C2.57174 0 1.6815 0.433134 1.02513 1.20412C0.368749 1.9751 0 3.02078 0 4.11111V24.6667C0 25.757 0.368749 26.8027 1.02513 27.5737C1.6815 28.3446 2.57174 28.7778 3.5 28.7778H15.75V24.6667H3.5V4.11111H31.5V24.6667H29.75V28.7778H31.5C32.4283 28.7778 33.3185 28.3446 33.9749 27.5737C34.6313 26.8027 35 25.757 35 24.6667V4.11111C35 3.02078 34.6313 1.9751 33.9749 1.20412C33.3185 0.433134 32.4283 0 31.5 0ZM15.75 10.2778H5.25V6.16667H15.75M12.25 16.4444H5.25V12.3333H12.25M15.75 22.6111H5.25V18.5H15.75V22.6111Z"
        {...mergedPathProps}
      ></path>
    </svg>
  );
};

export default MoneyOutline;
