import { LinkProps } from "@/components/CTA/variants/MultiCourse/descriptions/types";
import { ElementViewed } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementViewed";
import {
  DEFAULT_USE_IN_VIEW_OPTIONS,
  ElementType,
} from "@/constants/tagular/main";
import useElementEvents from "@/hooks/eventing/useElementEvents";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { translateVariant } from "@/utils/tagular/dataTranslationMatrices";

export const DescriptionLink = ({
  url,
  text,
  position,
  variant,
}: LinkProps) => {
  const webElement: ElementViewed["webElement"] = {
    elementType: ElementType.Entry,
    position: position,
    location: "goal-path-asset",
    text: text,
    name: "all",
    htmlId: "product-link",
    variant: translateVariant(variant),
  };

  const { elementViewed, elementClicked } = useElementEvents({ webElement });

  const { inView, ref } = useInView(DEFAULT_USE_IN_VIEW_OPTIONS);

  useEffect(() => {
    if (inView) {
      elementViewed();
    }
  }, [inView, elementViewed]);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    elementClicked();
  };

  return (
    <a
      ref={ref}
      className="text-link underline"
      href={url}
      onClick={handleClick}
    >
      {text}
    </a>
  );
};
