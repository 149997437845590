"use client";

import { PropsWithChildren, useEffect, useState } from "react";
import { LeadFormPluginOptions } from "@/types/LeadFormPlugin";
import logger from "@/lib/logger";

type FormData = Record<string, unknown>;

const getTaxiFormData = async ({
  code,
  id,
}: {
  code: string;
  id: string;
}): Promise<FormData | null> => {
  if (!code || !id) return null;
  const taxiFormDetails = await fetch(
    `https://corp-mktg.s3.us-west-2.amazonaws.com/taxi/production/packages/${code}/${id}.json`,
  );
  if (!taxiFormDetails.ok) {
    const message = "Error fetching taxi form data";
    logger.error(message);
    window.newrelic.noticeError(new Error(message));
    return null;
  }
  return taxiFormDetails.json();
};

function injectTaxiForm(onLoadCallback: () => void) {
  // if we've already loaded the script, don't load it again
  if (window.LeadFormPlugin) {
    onLoadCallback();
    return;
  }

  const script = document.createElement("script");
  script.src =
    "https://prospect-form-plugin.2u.com/cask-latest/lead.form.plugin.js";
  script.async = true;

  script.onload = onLoadCallback; // Call the callback function when the script has loaded

  document.body.appendChild(script);
}

export default function useTaxiForm(
  formGrouping: string,
  formId: string,
  postSubmitUrl: string,
) {
  const [taxiFormData, setTaxiFormData] = useState<FormData | null>(null);

  const salt = "___";
  const packageFromId = `${salt}-${formGrouping}-${formId}-full-taxi-lead-form-package`;
  const templateFromId = `${salt}-lead-form-template`;
  const mountPointId = `${salt}-taxi-plugin-mount-point`;
  const titleId = `${salt}-title`;
  const introTextId = `${salt}-intro_text`;
  const progressBarId = `${salt}-lead-form-progress-bar-mount-point`;
  const errorMountPointId = `${salt}-lead-form-error-mount-point`;
  const fieldMountPointId = `${salt}-lead-form-field-mount-point`;
  const nextActionButtonId = `${salt}-lead-form-next-action-button`;
  const footerMountPointId = `${salt}-lead-form-footer-mount-point`;

  useEffect(() => {
    getTaxiFormData({ code: formGrouping, id: formId }).then((data) => {
      if (!data) return;
      setTaxiFormData(data);
    });
  }, [formGrouping, formId]);

  useEffect(() => {
    injectTaxiForm(() => {
      if (!taxiFormData) return;
      const options: LeadFormPluginOptions = {
        salt: salt,
        thankYouURL: postSubmitUrl,
        packageType: "element",
        packageFrom: packageFromId,
        templateType: "element",
        templateFrom: templateFromId,
        telemetryEnabled: false,
        hostPresetFields: {
          ip: "", // window.visitor?.ip?.address || "0.0.0.0",
          lead_source: "edx",
          // page_url: pageUrlWithEAID,
          eaid: "",
          // splash_creative: '', // TODO: figure out how to supply this
        },
      };

      const taxiForm = new window.LeadFormPlugin(
        window.document,
        mountPointId,
        options,
      );
      taxiForm.start();
    });
  }, [
    mountPointId,
    packageFromId,
    postSubmitUrl,
    templateFromId,
    taxiFormData,
  ]);

  const TaxiForm = ({ children }: PropsWithChildren) => {
    if (!taxiFormData) return null;
    return (
      <section className="taxi-form-section">
        <div className="taxi-form">
          <pre id={packageFromId} className="hidden">
            {JSON.stringify(taxiFormData)}
          </pre>
          <div
            id={mountPointId}
            data-package-type="element"
            data-template-type="element"
          />
        </div>
        <code id={templateFromId} className="hidden">
          {children}
        </code>
      </section>
    );
  };

  return {
    TaxiForm,
    templateIds: {
      titleId,
      introTextId,
      progressBarId,
      errorMountPointId,
      fieldMountPointId,
      nextActionButtonId,
      footerMountPointId,
    },
  };
}
